import React from "react"
import logo from "./../images/logo.png"

export default () => (
  <div
    style={{
      padding: "21% 10%",
      textAlign: "center",
      fontFamily: "Avenir Next !important",
    }}
    className="thanks"
  >
    <img
      style={{
        width: "50%",
        height: "auto",
      }}
      className="brand"
      src={logo}
      alt="Company Logo"
    />
    <h3
      style={{
        fontSize: "1.25rem",
        fontWeight: "500",
      }}
    >
      Thank you for your submit / Gracias por su correo
    </h3>
    <p
      style={{
        padding: "15px 32px",
      }}
    >
      <a href="/">
        <button
          style={{
            backgroundColor: "#82cc00",
            border: "none",
            color: "white",
            padding: "15px 32px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            borderRadius: "2px",
          }}
          className="button"
        >
          Home
        </button>
      </a>
    </p>
  </div>
)
